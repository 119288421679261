import Vue from 'vue'
import Vuei18n from 'vue-i18n'
// import fr from './languages/fr.json'
import en from './languages/en.json'
import it from './languages/it.json'
import es from './languages/es.json'
// import de from './languages/de.json'

Vue.use(Vuei18n)

const i18n = new Vuei18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    // fr,
    en,
    it,
    es,
    // de,
  },
  silentTranslationWarn: process.env.NODE_ENV === 'production',
})

export default i18n
